import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Comparendo} from '../../../commons/interfaces/types/comparendos';
import {WebStorageCustomService} from '../../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-resultado-consulta',
  templateUrl: './resultado-consulta.component.html',
  styleUrls: ['./resultado-consulta.component.scss']
})
export class ResultadoConsultaComponent implements OnInit {
  @Input() data: {
    result: Comparendo[];
    placa: string;
    documento: string;
    date: Date;
  };
  @Output()
  makeNewQuery = new EventEmitter<any>();
  dateString: string;

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.getDate(this.data.date);
    /*if (this.data.result.length > 0) {
      this.data.result = [this.data.result[this.data.result.length - 1]];
    }*/
    console.log(this.data.result);
  }

  getDate(fecha: Date | string): string {
    if (typeof fecha === 'string') {
      fecha = new Date(fecha);
    }
    this.dateString = this.datePipe.transform(fecha, 'dd/MM/yyyy HH:mm:ss');
    return this.dateString;
  }

  emitNewQuery() {
    this.makeNewQuery.emit();
  }

  async downloadComparendo(pdfs: string, comparendo: any) {
    if (pdfs && JSON.parse(pdfs).length > 0) {
      const link = window.document.createElement('a');
      link.target = '_blank';
      link.download = 'pdf.pdf';
      /*await this.fetchFile(JSON.parse(pdfs)[0])
        .then((response) => {
          link.href = response;
        });*/
      link.href = JSON.parse(pdfs)[0];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  fetchFile(url: string): Promise<any> {
    return new Promise((resolve) => {
      fetch(url, {mode: 'no-cors'})
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(blob);
        });
    });
  }

}
