import {Component, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';

// Own
// Services
import {NotificacionesService} from '../../providers/notificaciones.service';
import {EventEmitterService} from '../../providers/event-emitter.service';
// Types
declare var $;

import {Usuario} from '../../models/usuario';
import {Response} from '../../commons/interfaces/types/response';
import {Notificacion} from '../../commons/interfaces/types/notificacion';
import {WebStorageCustomService} from '../../commons/providers/web-custom-storage.service';

@Component({
  selector: 'app-lista-notificaciones',
  templateUrl: './lista-notificaciones.component.html',
  styleUrls: ['./lista-notificaciones.component.scss']
})
export class ListaNotificacionesComponent implements OnInit {
  notificaciones: Notificacion[] = [];
  page = 0;
  usuario: any;
  totalNoticaciones: number;
  loading = false;

  constructor(private _notificacionesService: NotificacionesService,
              private _eventEmitterService: EventEmitterService,
              private datePipe: DatePipe,
              private webStorageCustomService: WebStorageCustomService,
              private router: Router) {
  }

  ngOnInit() {
    $(window).scroll(() => {
      if ($(window).scrollTop() + window.innerHeight === $(document).height()) {
        this.cargarMas();
      }
    });

    this.usuario = this.webStorageCustomService.getFromLocal('usuario') ? <Usuario>JSON.parse(this.webStorageCustomService.getFromLocal('usuario')) : null;
    if (this.usuario !== null) {
      // Get user notifications
      this.getNotificaciones(this.usuario.id, this.page, 10);
      // Set notifications as readed
      this.setNotificacionesAsReaded(this.usuario.id);
      // Subscribe new notifications
      this.subscripcionNuevasNotificaciones();
    }
  }

  public cargarMas() {
    this.page++;
    this.getNotificaciones(this.usuario.id, this.page, 7);
  }

  public getClass(prefix: string, notificacion: Notificacion): string {
    return `${prefix}${notificacion.tipoNotificacion.toLowerCase()}`;
  }

  public getDate(fecha: Date | string): string {
    if (typeof fecha === 'string') {
      fecha = new Date(fecha);
    }
    const dateString = this.datePipe.transform(fecha, 'dd/MM/yyyy HH:mm:ss');
    return dateString;
  }

  public verComparendo(notification: Notificacion) {
    let url = notification.url;
    console.log(notification);
    let params = {};
    if (notification.paramId) {
      url += `/${notification.paramId}`;
    }
    if (notification.queryParams) {
      let jsonString = notification.queryParams;
      // preserve newlines, etc - use valid JSON
      jsonString = jsonString.replace(/\\n/g, '\\n')
        .replace(/\\'/g, '\\\'')
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f');
      // remove non-printable and other non-valid JSON chars
      jsonString = jsonString.replace(/[\u0000-\u0019]+/g, '');
      console.log(jsonString);
      params['queryParams'] = JSON.parse(jsonString);
    }
    this.router.navigate([url], params);
  }

  private getNotificaciones(userId: number, page: number, size) {
    if (!this.totalNoticaciones ? true : this.notificaciones.length < this.totalNoticaciones) {
      this.loading = true;
      this._notificacionesService.query(userId, {
        page,
        size,
        sort: ['id' + ',' + 'desc']
      })
        .subscribe((response: Response) => {
          this.notificaciones = this.notificaciones.concat(response.content);
          this.totalNoticaciones = response.totalElements;
          this.loading = false;
        }, (error) => {
          console.log('Error al traer las notificaciones ', error);
          this.loading = false;
        });
    }
  }

  private subscripcionNuevasNotificaciones() {
    this._eventEmitterService.getNotificacion()
      .subscribe((response: Notificacion) => {
        if (response) {
          this.notificaciones.unshift(response);
        }
      });
  }

  private setNotificacionesAsReaded(userId: number) {
    this._notificacionesService.updateEstados(true, userId)
      .subscribe((res) => {
        console.log('NOTIFICACIONES ACTUALIZADAS');
      }, (err) => {
        console.log('ERROR ACTUALIZAR NOTIFICACIONES');
      });
  }

}
