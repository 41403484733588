import { environment } from '../../../environments/environment';
// Jsons
import jsonRespuestaRUNTReferencia from '../../../assets/json/referenciaRespuestRUNT.json';

// Web recaptcha key
export const RECAPTCHA_KEY = environment.recaptchaKey;
export const SHOW_ENTRIES_OPTIONS = [5, 10, 15, 20];
export const RESPUESTA_RUNT_EXCEL_CONFIGURATION = {
    jsonRespuestaRUNTReferencia,
    maxArrayItems: 2
};

export const ESTADOCOMPARENDOS = {
    idComparendoCreado: 1,
    idComparendoPorCertificar: 2,
    idComparendoCertificado: 3,
    idComparendoRechazado: 4,
    idComparendoDescertificado: 5,
    idComparendoEnviadoSIMIT: 6,
    idComparendoEnviadoMOVILIZA: 7,
    idComparendoEnviadoSystrans: 8,
};

export const KEYS_TO_MOVILIXA_RESPONSE_DESCRIPTION = 'movilixaResponse.check';

export const VIDEO_FORMATS_ALLOWED: string[] = [
    /*'webm',
    'mkv',
    'flv',
    'vob',
    'ogv',
    'ogg',
    'gif',
    'gifv',
    'mng',
    'avi',
    'MTS',
    'M2TS',
    'mov',
    'qt',
    'wmv',
    'yuv',
    'rm',
    'rmvb',
    'asf',
    'amv',*/
    'mp4',
    /*'m4p',
    'm4v',
    'mpg',
    'mp2',
    'mpeg',
    'mpe',
    'mpv',
    'mpg',
    'mpeg',
    'm2v',
    'm4v',
    'svi',
    '3gp',
    '3g2',
    'mxf',
    'roq',
    'nsv',
    'flv',
    'f4v',
    'f4p',
    'f4a',
    'f4b'*/
];

