const production = true;

function getAPI(isProduccion) {
  if (isProduccion) { return 'https://zipa-gateway-server.trafficsoft.co/'; }
  return 'http://localhost:8080/';
 //   return 'http://gateway-server.trafficsoft.co/';
}

export const environment = {
    production: production,
    apiUrl: getAPI(production),
    consultaRuntUrl: 'https://zipa-gateway-server.trafficsoft.co/consultas-externas/consulta-runt',
    recaptchaKey: '6LcKLqoaAAAAAIMuFTb9fYH-dZXE9t20b_Vp1kqz',
    consultasExternasUrl: 'https://zipa.trafficsoft.co/consultas-externas-public',
};
