import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { createRequestOption } from '../helpers/request-util';

@Injectable({
  providedIn: 'root'
})
export class TiposConsultaService {
  public resourceUrl = 'https://zipa-admin-server.trafficsoft.co/tipoConsulta';

  constructor(public http: HttpClient) {
  }

  listAll(): Observable<any[]> {
    return this.http.get<any[]>(this.resourceUrl);
  }
}
