import {Component, Input, HostBinding, OnInit} from '@angular/core';
import {AppService} from '../../app.service';
import {LayoutService} from '../../layout/layout.service';
import {AuthService} from '../../providers';
import {WebStorageCustomService} from '../../commons/providers/web-custom-storage.service';
import {Usuario} from '../../models/usuario';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';

// Services
import {UsuarioService} from '../../providers/usuario.service';
import {WebSocketService} from '../../providers/web-socket.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {NewEditUsuarioComponent} from '../../components/usuarios/modals/new-edit-usuario/new-edit-usuario.component';

@Component({
  selector: 'app-layout-navbar',
  templateUrl: './layout-navbar.component.html',
  styles: [':host { display: block; }'],
  styleUrls: ['./layout-navbar.scss']
})
export class LayoutNavbarComponent implements OnInit {
  isExpanded = false;
  isRTL = true;
  usuario: Usuario;

  @Input() sidenavToggle = true;

  @HostBinding('class.layout-navbar') public hostClassMain = true;

  constructor(public appService: AppService,
              public layoutService: LayoutService,
              private modalService: NgbModal,
              public authservice: AuthService,
              private usuarioService: UsuarioService,
              private datePipe: DatePipe,
              private webStorageCustomService: WebStorageCustomService,
              public webSocketService: WebSocketService,
              private router: Router) {
    // this.isRTL = appService.isRTL;
  }

  ngOnInit() {
    this.usuario = this.webStorageCustomService.getFromLocal('usuario') ? <Usuario>JSON.parse(this.webStorageCustomService.getFromLocal('usuario')) : null;

    this.init();
    //  If is authenticated initialize notifications
    if (this.usuario && !this.webSocketService.stompClient) {
      this.authservice.checkToken()
        .subscribe((response) => {
          console.log('CHECK TOKEN TO INITIALIZE SOCKET CONNECTION ', response);
          this.initializeSocketConnection();
        });
    }

  }

  viewAllNotifications() {
    this.router.navigate(['/notificaciones']);
    this.webSocketService.setNotificationsAsReaded(true, this.usuario.id);
  }

  openNotifications($event: boolean): void {
    if ($event) {
      this.webSocketService.setNotificationsAsReaded(true, this.usuario.id);
    }
  }

  getNotificationClass(notification: any): string {
    return `list-group-item-${notification.tipoNotificacion.toLowerCase()}`;
  }

  clickNotification(notification): void {
    this.webSocketService.setNotificationsAsReaded(true, this.usuario.id);
    let url = notification.url;
    console.log(notification);
    let params = {};
    if (notification.paramId) {
      url += `/${notification.paramId}`;
    }
    if (notification.queryParams) {
      let jsonString = notification.queryParams;
      // preserve newlines, etc - use valid JSON
      jsonString = jsonString.replace(/\\n/g, '\\n')
        .replace(/\\'/g, '\\\'')
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f');
      // remove non-printable and other non-valid JSON chars
      jsonString = jsonString.replace(/[\u0000-\u0019]+/g, '');
      console.log(jsonString);
      params['queryParams'] = JSON.parse(jsonString);
    }
    console.log('url', url);
    console.log('params', params);
    if (url === '/certificar-comparendos') {
      this.webStorageCustomService.saveToLocal('comparendoId', params['queryParams'].comparendo);

    }
    this.router.navigate([url], params);
  }

  private initializeSocketConnection() {
    this.webSocketService.initializeWebSocketConnection();
  }

  private closeSocketConnection() {
    return this.webSocketService.disconnect();
  }

  currentBg() {
    return `bg-${this.appService.layoutNavbarBg}`;
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }

  async logOut() {
    await this.closeSocketConnection();
    this.authservice.logout();
  }

  public open() {
    const modal: NgbModalRef = this.modalService.open(NewEditUsuarioComponent, {});

    modal.componentInstance.usuario = this.usuario;

    modal.result.then(
      (success: Object) => {
      },
      (error: any) => {
        // console.error(error);
      }
    );

  }

  async init() {

    await this.usuarioService
      .find(this.usuario.id)
      .toPromise().then(
        (res: any) => {
          this.usuario = res;
        },
        (err) => {
        }
      );
  }

  public getDate(fecha: Date | string): string {
    if (typeof fecha === 'string') {
      fecha = new Date(fecha);
    }
    const dateString = this.datePipe.transform(fecha, 'dd/MM/yyyy HH:mm:ss');
    return dateString;
  }
}
