import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createRequestOption } from '../helpers/request-util';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

// Own
// Types
import { ColumnDefinitions } from '../commons/interfaces/types/column-definitions';

@Injectable({
  providedIn: 'root'
})
export class ConsultasExternasReportesService {
  public resourceUrl = 'https://zipa-admin-server.trafficsoft.co/externalConsultationLog';
  private chartBaseObject = {
    type: 'bar',
    data: {
      labels: [],
      datasets: [
        {
          label: '',
          backgroundColor: [],
          data: []
        }
      ]
    },
    options: {
      legend: { display: false, position: 'bottom' },
      title: {
        display: true,
        text: ''
      },
      colors: [],
      animation: null,
      scales: {
        yAxes: [{
          display: true,
          ticks: {
            suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
            // OR //
            beginAtZero: true,   // minimum value will be 0.
            precision: 0
          }
        }]
      }
    },
  };
  private colors: string[] = [
    '#057f01',
    '#800000'
  ];
  constructor(private http: HttpClient) { }

  // REPORTE ESTADOS

  reporteEstadosData(req: { [key: string]: any }): Observable<{ [key: string]: any }> {
    const options = createRequestOption(req);
    return this.http.get<{ [key: string]: any }>(`${this.resourceUrl}/registrosbyTipoAndError`, { params: options });
  }

  getReporteEstadosColumnDefinitions(): ColumnDefinitions[] {
    const columns: ColumnDefinitions[] = [
      {
        'columnName': 'Nombre base',
        'valueGetter': (item) => item.baseNombre,
        'columnType': 0
      },
      {
        'columnName': 'Descripci�n base',
        'valueGetter': (item) => item.baseDescripcion,
        'columnType': 0
      },
      {
        'columnName': 'Exitosas',
        'valueGetter': (item) => item.exitosas,
        'columnType': 0
      },
      {
        'columnName': 'Fallidas',
        'valueGetter': (item) => item.fallidas,
        'columnType': 0
      },
      {
        'columnName': 'Acciones',
        'columnType': 1,
        'actions': [
          {
            'text': 'Ver Detalle',
            'class': 'btn btn-outline-light btn-sm icon-btn',
            'iconClass': 'ion ion-md-eye icon-create-edit',
            'getActionParam': (item) => {
              return {
                key: `consulta_externa`,
                param: item.id
              };
            },
            'getQueryFunction': (item) => {
              console.log(item);
              return {
                eliminado: false
              };
            }
          }
        ]
      }
    ];
    return columns;
  }

  getReporteEstadosDataChart(content: any[]): any {
    const chart = JSON.parse(JSON.stringify(this.chartBaseObject));
    chart.options.title.text = 'Estados';
    chart.options.legend.display = true;
    chart.data.datasets = [];
    content.forEach((element) => {
      chart.data.labels.push(element.baseNombre);
    });

    for (let index = 0; index < 2; index++) {
      const data = [];
      content.forEach((cont) => {
        data.push(index === 0 ? cont.exitosas : cont.fallidas);
      });
      chart.data.datasets.push({
        'data': data,
        'backgroundColor': this.colors[index],
        'label': index === 0 ? 'Exitosas' : 'Fallidas'
      });
    }

    console.log('GENERAR GRAFICO CON ', content, 'CHART ', chart);
    return chart;
  }

  query(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any[]>(`${this.resourceUrl}/consultaExternaReporte`, { params: options });
  }

  queryCount(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.http.get<any>(`${this.resourceUrl}/find_by_criteria_count`, { params: options });
  }
}
